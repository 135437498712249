import React from 'react'
import './style.scss'

import { images } from 'assets'

const Home = (props) => {
  return (
    <div className='home-container'>
      <img className='home-hero' src={images.hero} alt='hero' />
      <div className='home-content'>
        <p>I'm Tarun.</p>
        <p>
          I'm a frontend engineer with expertise in creating web and mobile experiences.
          I have extensively developed apps utilizing React, React Native and have lately been dabbling with Flutter. 
          Since you're already here, check out my <a href='/resume.html'>resume</a> or
          say <a href='mailto:tpkhera@gmail.com'>hello</a>!
        </p>
      </div>
    </div>
  )
}

export default Home
