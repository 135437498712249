const Images = {
  logo: require('./calm-baba.png'),
  gen: require('./calm-logo.png'),
  wealthy: require('./mockup-wealthy.png'),
  accio: require('./mockup-accio.png'),
  qlock: require('./mockup-qlock.png'),
  hero: require('./hero.svg')
}

export default Images
