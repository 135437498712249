import React from 'react'
import './style.scss'

const SocialLinks = (props) => {
  return (
    <div className='social-links'>
      <a href='mailto:tpkhera@gmail.com'><i className='far fa-envelope' /></a>
      <a href='https://gitlab.com/tpkhera'><i className='fab fa-gitlab' /></a>
      <a href='https://www.linkedin.com/in/tpkhera/'><i className='fab fa-linkedin-in' /></a>
    </div>
  )
}

export default SocialLinks
