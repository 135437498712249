import React from 'react'
import { NavLinks } from 'components'
import { images } from 'assets'
import './style.scss'

const Header = (props) => {
  return (
    <div className='calm-header'>
      <div className='header-content'>
        <div className='calm-logo'>
          <span>[</span>
          <img src={images.logo} alt='logo' />
          <span>]</span>
        </div>
        <div className='header-separator' />
        <NavLinks theme='dark' />
        {/* <BurgerMenu /> */}
      </div>
    </div>
  )
}

export default Header
