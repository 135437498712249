import React from 'react'
import { images } from 'assets'
import './style.scss'

const Projects = (props) => {
  return (
    <div className='projects-container'>
      <h1 className='project-section-heading'>Projects</h1>
      <section className='project-section'>
        <div className='project-info'>
          <div className='info-container'>
            <span className='project-header'>Wealthy.in</span>
            <span className='project-description'>
              Wealthy is a personal wealth management robo-advisory platform which
              also offers personalized advice from dedicated financial planners and
              wealth managers. Launched in 2016, Wealthy now manages over $100M in assets.
            </span>
            <span className='project-tech'>
              <i className="devicon-react-original colored"></i>
              <i className="devicon-nginx-original colored"></i>
              <i className="devicon-amazonwebservices-original colored"></i>
              <i className="devicon-docker-plain colored"></i>
            </span>
            <a href="https://wealthy.in" className='project-cta'>More</a>
          </div>
        </div>
        <div className='project-graphic'>
          <img src={images.wealthy} alt='' />
        </div>
      </section>
      <section className='project-section'>
        <div className='project-info'>
          <div className='info-container'>
            <span className='project-header'>Accio</span>
            <span className='project-description'>
              Accio is a self-hosted app that helps to download and store videos from
              Youtube, Vimeo and many other video hosting platforms. It utilizes the excellent
              youtube-dl behind the scenes for downloading and processing the videos.
            </span>
            <span className='project-tech'>
              <i className="devicon-react-original colored"></i>
              <i className="devicon-sass-original colored"></i>
              <i className="devicon-nodejs-plain-wordmark colored"></i>
              <i className="devicon-docker-plain colored"></i>
            </span>
            <a href="https://gitlab.com/tpkhera/pi-dl-ui" className='project-cta'>More</a>
          </div>
        </div>
        <div className='project-graphic'>
          <img src={images.accio} alt='' />
        </div>
      </section>
      <section className='project-section'>
        <div className='project-info'>
          <div className='info-container'>
            <span className='project-header'>Qlock</span>
            <span className='project-description'>
              Inspired by products from <a href='https://qlocktwo.com/en/'>Qlocktwo</a>, this
              started as a weekend project that was born out of a desire to digitally 
              recreate real-world products.
            </span>
            <span className='project-tech'>
              <i className="devicon-html5-plain colored"></i>
              <i className="devicon-javascript-plain colored"></i>
              <i className="devicon-sass-original colored"></i>
              <i className="devicon-webpack-plain colored"></i>
            </span>
            <a href="/qlock.html" className='project-cta'>More</a>
          </div>
        </div>
        <div className='project-graphic'>
          <img src={images.qlock} alt='' />
        </div>
      </section>
    </div>
  )
}

export default Projects
