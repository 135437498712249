import React from 'react'

import './style.scss'

const Contact = (props) => {
  return (
    <div className='contact-container'>
      <div className='contact-list'>
        <h1>Get in touch</h1>
        <div className='contact-item'>
          <i className='fab fa-angellist' />
          <a href='https://angel.co/u/tpkhera'>Angel.co</a>
          <span>angel.co/u/tpkhera</span>
        </div>
        <div className='contact-item'>
          <i className='fab fa-linkedin-in' />
          <a href='https://www.linkedin.com/in/tpkhera/'>Linkedin</a>
          <span>linkedin.com/in/tpkhera</span>
        </div>
        <div className='contact-item'>
          <i className='far fa-envelope' />
          <a href='mailto:tpkhera@gmail.com'>Email</a>
          <span>tpkhera@gmail.com</span>
        </div>
        <div className='contact-item'>
          <i className='fab fa-skype' />
          <a href='skype:tpkhera?userinfo'>Skype</a>
          <span>tpkhera</span>
        </div>
      </div>
    </div>
  )
}

export default Contact
