import React from 'react'
import { SocialLinks } from 'components'
import { images } from 'assets'
import './style.scss'

const Footer = (props) => {
  return (
    <div className='calm-footer'>
      <div className='footer-content'>
        <div className='calm-logo'>
          <span>[</span>
          <img src={images.logo} alt='logo' />
          <span>]</span>
        </div>
        <div className='footer-separator' />
        <SocialLinks />
      </div>
    </div>
  )
}

export default Footer
