import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import { Header, Footer } from './components'
import { Home, Projects, Contact } from './routes'
import './App.scss'

export default function AppRoutes () {
  return (
    <Router>
      <Header />
      <div className='route-container'>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/projects' component={Projects} />
          <Route path='/contact' component={Contact} />
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}
