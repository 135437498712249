import React from 'react'
import { Link } from 'react-router-dom'

import './style.scss'

const NavLinks = (props) => {
  const { theme } = props
  return (
    <div className={`nav-links ${theme}`}>
      <Link to='/'>Home</Link>
      <Link to='/projects'>Portfolio</Link>
      <Link to='/contact'>Contact</Link>
    </div>
  )
}

export default NavLinks
